import React, { useEffect, useState } from 'react'
import { Box, LabelStyled, Span, SpanStyled } from '../Styled/Elements';
import { BMIImage, BMIPin } from '../Styled/AllImages';
import { useTranslation } from 'react-i18next';

const FindValue = () => {
    const { t } = useTranslation();
    const [age, setAge] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [gender, setGender] = useState('');
    const [resultValue, setResultValue] = useState(null);
    const [resultCategory, setResultCategory] = useState('');
    const [resultMeter, setResultMeter] = useState('');

    const calculateBMI = () => {
        const heightInMeters = height / 100;
        const bmi = weight / (heightInMeters * heightInMeters);

        // Determine BMI category
        let category = '';
        let meterFind = '';
        if (bmi < 18) {
            category = 'Underweight';
            meterFind = bmi < 18 ? (bmi * 2.43) : '';
        } else if (bmi >= 18 && bmi < 24.9) {
            category = 'Normalweight';
            meterFind = bmi >= 18 && bmi < 24.9 ? ((bmi - 18) * 6.42) + 45 : '';
        } else if (bmi >= 25 && bmi < 29.9) {
            category = 'Overweight';
            meterFind = bmi >= 25 && bmi < 29.9 ? ((bmi - 25) * 9) + 90 : '';
        } else {
            category = 'Obese';
            // meterFind = ((bmi - 30) * 1.50) + 135;
        }
        setResultValue(bmi.toFixed(2));
        setResultCategory(category);
        setResultMeter(meterFind);
    };
    const meter = {
        transform: `rotate(${resultMeter}deg)`,
        // Add more styles as needed
    };
    const resultFinal =
        resultCategory === 'Underweight' ? 'warning' :
            resultCategory === 'Normalweight' ? 'success' :
                resultCategory === 'Overweight' ? 'secondary' :
                    resultCategory === 'Obese' ? 'danger' : '';

    return (
        <Box className='BMI-contanier'>
            <Box className='percentage'>
                <Box className='box-wrapper my-4'>
                    <Box className='percentage-field'>
                        <Box className='unit-converter'>
                            <LabelStyled>{t('Age')}:</LabelStyled>
                            <input type="text" placeholder={t('Age')} value={age} onChange={(e) => setAge(e.target.value)} />
                        </Box>
                        <Box className='unit-converter'>
                            <LabelStyled>{t('Height')}:</LabelStyled>
                            <input type="text" placeholder={t('cm')} value={height} onChange={(e) => setHeight(e.target.value)} />
                        </Box>
                        <Box className='unit-converter'>
                            <LabelStyled>{t('Weight')}:</LabelStyled>
                            <input type="text" placeholder={t('kg')} value={weight} onChange={(e) => setWeight(e.target.value)} />
                        </Box>
                        <Box className='unit-converter gender-container'>
                            <LabelStyled>{t('Gender')}:</LabelStyled>
                            <Box className='d-flex gap-2'>
                                <Box className='gender'>
                                    <input type="radio" id='male' name='gander' value='male' checked={gender === 'male'} onChange={() => setGender('male')} />
                                    <LabelStyled for='male'>{t('Male')}</LabelStyled>
                                </Box>
                                <Box className='gender'>
                                    <input type="radio" id='female' name='gander' value='female' checked={gender === 'female'} onChange={() => setGender('female')} />
                                    <LabelStyled for='female'>{t('Female')}</LabelStyled>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    <Box className='button-select'>
                        <button className='btn btn-info mt-4' onClick={calculateBMI} >
                            {t('Calculate')}
                        </button>
                    </Box>

                    {
                        resultValue !== null && (
                            <Box className='mt-4 d-flex flex-column gap-2 align-items-center justify-content-center'>
                                <SpanStyled className={`btn btn-outline-${resultFinal} mx-2`}>{t('Result')}: {resultValue}</SpanStyled>
                                <SpanStyled className={`btn btn-${resultFinal}  mx-2`} >
                                    {t('Category')}: {resultCategory}
                                </SpanStyled>
                            </Box>
                        )
                    }
                </Box>
            </Box>
            <Box className='percentage-content'>
                <img src={BMIImage} alt="img" />
                <Span className={`bmi-pin ${resultFinal}  `} >
                    <img style={meter} src={BMIPin} alt="img" />
                </Span>
            </Box>
        </Box >

    )
}

export default FindValue