import React from 'react'
import { Box, HeadingStyled, MainHeading, TextStyled } from '../Styled/Elements'
import FindValue from './FindValue';
import { Container } from 'react-bootstrap';
import ChangeButton from '../../Languages/ChangeButton'
import { useTranslation } from 'react-i18next';

const Percentage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Container fluid >
                <Box className='content-main-container'>
                    <Box>
                        <Box className=''>
                            <MainHeading size='25px'> {t('BMI_Calculator')} </MainHeading>
                            <TextStyled> {t('BMI_Calculator_content')}</TextStyled>
                        </Box>
                        <FindValue />
                    </Box>
                </Box>
                <Box className='Faq-content'>
                    <Box>
                        <HeadingStyled> {t('BMI_introduction')}</HeadingStyled>
                        <TextStyled> {t('BMI_introduction_content')}</TextStyled>
                    </Box>
                    <Box>
                        <HeadingStyled>{t('Risks')}</HeadingStyled>
                        <TextStyled> {t('Risks_content')}
                            <ul>
                                <li> {t('High_blood_pressure')}</li>
                                <li>{t('Higher_levels')}</li>
                                <li> {t('Type_II_diabetes')}</li>
                                <li> {t('Coronary')}</li>
                                <li> {t('Stroke')}</li>
                                <li> {t('Gallbladder_disease')}</li>
                                <li> {t('Osteoarthritis')}</li>
                                <li> {t('Sleep')}</li>
                                <li> {t('Certain')}</li>
                                <li> {t('Low')}</li>
                                <li> {t('Mental')}</li>
                                <li> {t('Body_pains')}</li>
                                <li> {t('Body_pains')}</li>
                            </ul>
                            {t('As_can_be')}
                        </TextStyled>
                    </Box>
                </Box>
                <ChangeButton />
            </Container>
        </>
    )
}

export default Percentage